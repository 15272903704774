<template>
  <v-container fluid>
    <v-tabs v-model="selectedTab">
      <v-tab v-for="(item, cndex) in items" :key="cndex">
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item v-for="(item, cndex) in items" :key="'xxxxaaa' + cndex">
        <CookingProductTab
          v-bind="$attrs"
          :detailview="true"
          :config="citem"
          v-for="(citem, index) in item.children"
          :key="'children' + index"
        ></CookingProductTab>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { sync } from "vuex-pathify";
export default {
  components: {
    CookingProductTab: () => import("./CookingProductTab"),
  },
  data: () => ({
    selectedTab: null,
    hhh: 400,
    items: null,
  }),
  computed: {
    ...sync("*"),
  },
  created() {
    this.items = this.userData.school
      ? [
          {
            name: "Бараа таталт",
            children: [
              {
                name: "Бараа татан авалт",
                shortName: "Бараа таталт",
                description:
                  "Татан авсан бараа, материалын бүртгэлийг энд удирдана.",
                fbpath: this.userData.school.ref.path + "/_cookingBuyProducts",
                schoolPath: this.userData.school.ref.path
              },
            ],
          },
        ]
      : null;
  },

  methods: {},
};
</script>
